import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import Checkbox from "@mui/material/Checkbox";
import { Avatar } from "@mui/material";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from 'react-redux';
import Fade from "@mui/material/Fade";
import Skeleton from "@mui/material/Skeleton";
import { useState } from "react";
import { Article } from "../../../interfaces/Article";
import { TransitionProps } from "@mui/material/transitions";
import { BasketItem } from "../../../interfaces/core/BasketItem";
import { addFavoriteItem, addProductToBasket, checkFavoriteItem } from "../../../services/core-api";
import { addToCart } from "../../../store/reducers/basket";
import { useSnackbar } from "../SnackbarContext";

interface State extends SnackbarOrigin {
  openNotification: boolean;
}

const ProductOffer = (props: any) => {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openBasketNotification, setOpenBasketNotification] =
    React.useState(false);
    const [basketItem, setBasketItem] = React.useState<BasketItem | null>(null);
    const dispatch = useDispatch();
    const { showSnackbar } = useSnackbar();
  const { product } = props;
  const defaultImage = "./no-image.png";
  const productPrice = Number(product.price) || 0;
  const price = productPrice.toFixed(2);
  const [isFavoriteItem, setIsFavoriteItem] = React.useState<Boolean | null>(null);


  // Toggle the checked state when the heart icon is clicked
  const handleCheckboxChange = (product: any) => {
    setChecked(!checked);
    if(product) {
      (async () => {
        try {
          const favoriteItem = {
            product_id: product.productId,
            favorite_id: 0,
            articleNumber: product.articleNumber,
            mfrName: product.mfrName,
            dataSupplierId: product.dataSupplierId,
            internalNumber: product.internalNumber,
            image: product.image ? product.image : "",
            description: product.description ? product.description : "",
            quantity: 1,
            price: product.price,
            totalPrice: product.price,
            available: true
          };
          await addFavoriteItem(favoriteItem);
          showSnackbar("Product added to Favorites successfully!", "success");
          setIsFavoriteItem((prev) => !prev);
        }catch(error){
          showSnackbar("Product not added to Favorites!", "error");
        }
      })()
    }
  };

    // Add article to basket
    const addToBasket = (
      article: Article,
      newStateBasketNotification: SnackbarOrigin
    ) => {
      setStateBasketNotification({
        ...newStateBasketNotification,
        openNotification: true,
      });
      setOpenBasketNotification(true);
      let description = '';
      let articleCriteria = article.articleCriteria || [];
      articleCriteria.forEach((criteria: any) => {
        if (criteria?.criteriaDescription && criteria?.formattedValue) {
          description += `<b style="font-weight: 500">${criteria.criteriaDescription}</b>: ${criteria.formattedValue}; `;
        }
      });
      
      const item:BasketItem = {
        productId: product.productId,
        internalNumber: article.internalNumber ?? '',
        articleNumber: product.articleNumber,
        mfrName: product.mfrName,
        dataSupplierId: product.dataSupplierId,
        image: product.image,
        description: product.description,
        price: product.price,
        quantity: 1,
        totalPrice: parseFloat((product.price)),
        available: true
      }
      
      setBasketItem(item);
      const user = localStorage.getItem('user');
      if (user) {
        const parsedUser = JSON.parse(user);
        const user_id = parsedUser.id;
      (async () => {
        try {
          await addProductToBasket(user_id, item);
          dispatch(addToCart(item));
          showSnackbar('Product added to basket successfully!', 'success');
        } catch (error) {
          showSnackbar('Failed to add product to basket.', 'error');
        }
      })()
      
    }
  
    };

  
    const [stateBasketNotification, setStateBasketNotification] =
      React.useState<State>({
        openNotification: false,
        vertical: "top",
        horizontal: "center",
      });

  return (
    <>
      {loading ? (
        <>
          <Skeleton variant="rectangular" sx={{width: '90%', height: '100px'}} />
          <Box sx={{ pt: 0.5 }}>
            <Skeleton sx={{width: '90%', height: '10px'}} />
            <Skeleton width="60%" />
          </Box>
        </>
      ) : (
        <Card
          sx={{
            maxWidth: 225,
            border: "7px solid #f1f1f1",
            borderRadius: "0",
            boxShadow: "none",
          }}
        >
          <CardHeader
            action={
              <Avatar
                sx={{ bgcolor: "transparent", borderRadius: "0" }}
                variant="rounded"
                onClick={()=> handleCheckboxChange(product)} // Handle click event on the heart icon
              >
                <Checkbox
                  icon={<FavoriteBorder sx={{ color: red[600] }} />} // Empty heart icon
                  checkedIcon={<Favorite sx={{ fill: red[600] }} />} // Filled heart icon
                  checked={checked} // Set the checked state based on the state value
                  inputProps={{ "aria-label": "Checkbox demo" }}
                />
              </Avatar>
            }
          />
          <CardMedia
            component="img"
            sx={{
              height: '180px',
              width: 'auto',
              margin: '0 auto',      
              display: 'block',      
              objectFit: 'contain',  
              maxHeight: '180px',  
              maxWidth: '180px'
            }}
            image={product.image || defaultImage}
            alt="Paella dish"
          />
          <CardContent>
            <Typography variant="body2" color="black">
              {product.manufacturer_name}
            </Typography>
            <Typography variant="body2" color="black">
              {product.articleNumber}
            </Typography>
          </CardContent>
          <CardActions
            disableSpacing
            style={{ justifyContent: "space-between" }}
          >
            <IconButton 
              aria-label="add to basket"
              onClick={() =>
                addToBasket(product, {
                  vertical: "top",
                  horizontal: "right",
                })
              }
            >
              <LocalGroceryStoreOutlinedIcon sx={{ color: "black" }} />
            </IconButton>
            <Typography
              variant="body2"
              color="text.primary"
              sx={{
                color: "black",
                fontWeight: "bold",
              }}
            >
              {product.price} EUR
            </Typography>
          </CardActions>
        </Card>
      )}
    </>
  );
};

export default ProductOffer;

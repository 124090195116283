import * as React from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Breadcrumbs,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import theme from "../../../theme";
import { Dayjs } from "dayjs";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Pagination from "@mui/material/Pagination";
import { ReadOrder } from "../../../interfaces/core/ReadOrder";
import {
  getAllUsers,
  getOrders,
  getUsers,
  updateOrderStatus,
  updateOrderVisited,
} from "../../../services/core-api";
import { UpdateOrder } from "../../../interfaces/core/UpdateOrder";
import { UsersResponse } from "../../../interfaces/core/UsersResponse";
import { UserRead } from "../../../interfaces/core/UserRead";

function Row(props: { row: ReadOrder; onStatusChange: (row: UpdateOrder) => void }) {
  const baseURL = process.env.REACT_APP_API_URL;
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const handleExportPdf = (id: number) => {
    const url = `${baseURL}/order/${id}/export`;
    window.open(url);
  };

  const handleReadOrderStatus = async (row: UpdateOrder) => {
    setOpen(!open);
    if (!row.visited) {
      try {
        const response = await updateOrderVisited(row);
        if (response) {
          row.visited = !row.visited;
          props.onStatusChange(row);
        }
      } catch (error) {}
    }
  };

  const handleStatusChange = async (
    event: React.ChangeEvent<{}>,
    newStatus: string | null
  ) => {
    if (newStatus && newStatus !== row.status) {
      try {
        // Call your updateOrderStatus function
        const updatedOrder = await updateOrderStatus({ id: row.id }, newStatus);

        // Update the status in your row if the request was successful
        row.status = updatedOrder.status;
        window.location.reload();
      } catch (error) {
      }
    }
  };

  const date = new Date(row.created_at);
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return (
    <React.Fragment>
      <TableRow key={row.id} sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell 
          sx={{ padding: "5px", cursor: "pointer" }}
          onClick={() => handleReadOrderStatus(row)}
        >
          <IconButton
            aria-label="expand row"
            size="small"
          >
            {open ? (
              <KeyboardArrowUpIcon sx={{ color: "#F37C2E" }} />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell align="left" 
          sx={{ padding: "5px", fontWeight: !row.visited ? 'bold' : 'normal', cursor: "pointer" }}
          onClick={() => handleReadOrderStatus(row)}
        >
          {row.id}
        </TableCell>

        <TableCell align="center" 
          sx={{ padding: "5px", fontWeight: !row.visited ? 'bold' : 'normal', cursor: "pointer" }}
          onClick={() => handleReadOrderStatus(row)}
        >
          {row.user.zone}
        </TableCell>

        {/* Conditionally bold the formatted date and time */}
        <TableCell align="center" 
          sx={{ padding: "5px", fontWeight: !row.visited ? 'bold' : 'normal', cursor: "pointer" }}
          onClick={() => handleReadOrderStatus(row)}
        >
          {`${formattedDate} ${formattedTime}`}
        </TableCell>

        {/* Conditionally bold the total */}
        <TableCell align="right"
          sx={{ padding: "5px", fontWeight: !row.visited ? 'bold' : 'normal', cursor: "pointer" }}
          onClick={() => handleReadOrderStatus(row)}
        >
          {row.total} EUR
        </TableCell>

        {/* Conditionally bold the payment */}
        <TableCell align="right" 
          sx={{ padding: "5px", fontWeight: !row.visited ? 'bold' : 'normal', cursor: "pointer" }}
          onClick={() => handleReadOrderStatus(row)}
        >
          {row.payment}
        </TableCell>

        <TableCell
          align="right"
          sx={{
            padding: "5px",
            color: "#2ccb32",
            fontSize: "13px",
            fontFamily: "Inter",
            fontWeight: 500,
          }}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={orderStatuses}
            value={row.status}
            onChange={handleStatusChange}
            sx={{
              bgcolor: "#fff",
              float: "right",
              width: "160px",
              "& .MuiOutlinedInput-root": {
                height: "30px",
                borderRadius: 0,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiInputBase-input": {
                height: "30px",
                padding: "0 14px",
                fontSize: "13px",
                fontWeight: !row.visited ? 'bold' : 'normal',
                fontFamily: "Inter",
                color:
                  row.status === "Processing"
                    ? "#F22"
                    : row.status === "Packed"
                    ? "#00F"
                    : row.status === "Delivered"
                    ? "#2ccb32"
                    : "#FFB122",
              },
              "& .MuiInputLabel-root": {
                color: "#5d5d5d",
                top: "-10px",
                fontSize: "13px",
                fontFamily: "Inter",
              },
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
          }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ m: 1, border: "2px solid #f1f1f1", p: 1 }}>
            <Box sx={{width: '100%'}}>
                <Typography sx={{ml:2, pt:2, mb:1, fontSize: "15px", fontFamily: "Inter", fontWeight: 500}}>
                  Buyer information
                </Typography>
              </Box>
            <Table size="small" aria-label="purchases">
                <TableHead sx={{ bgcolor: "#f1f1f1", p: 1 }}>
                  <TableRow>
                    <TableCell sx={{ fontFamily: "Inter", fontWeight: 600 }}>
                      Name
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Inter", fontWeight: 600 }}>
                      Surname
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Inter", fontWeight: 600 }}>
                      City
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Inter", fontWeight: 600 }}>
                      Country
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontFamily: "Inter", fontWeight: 600 }}
                    >
                      Phone
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontFamily: "Inter", fontWeight: 600 }}
                    >
                      Address
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontFamily: "Inter", fontWeight: 600 }}
                    >
                      Company
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {row.user.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.user.surname}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.user.city}
                      </TableCell>
                      <TableCell>
                        {row.user.country}
                      </TableCell>
                      <TableCell align="right">{row.user.phone}</TableCell>
                      <TableCell align="right">{row.user.address}</TableCell>
                      <TableCell align="right">
                        {row.user.company}
                      </TableCell>
                    </TableRow>
                </TableBody>
              </Table>
              <Box sx={{width: '100%'}}>
                <Typography sx={{ml:2, pt:2, mb:1, fontSize: "15px", fontFamily: "Inter", fontWeight: 500}}>
                  Order Items
                </Typography>
              </Box>
              <Table size="small" aria-label="purchases">
                <TableHead sx={{ bgcolor: "#f1f1f1", p: 1 }}>
                  <TableRow>
                    <TableCell sx={{ fontFamily: "Inter", fontWeight: 600 }}>
                      Nr
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Inter", fontWeight: 600 }}>
                      Article number
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Inter", fontWeight: 600 }}>
                      Manufacturer
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Inter", fontWeight: 600 }}>
                      Description
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontFamily: "Inter", fontWeight: 600 }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontFamily: "Inter", fontWeight: 600 }}
                    >
                      Price (&#8364;)
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontFamily: "Inter", fontWeight: 600 }}
                    >
                      Total price (&#8364;)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {item.internalNumber}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.articleNumber}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.mfrName}
                      </TableCell>
                      <TableCell>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.description,
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">{item.quantity}</TableCell>
                      <TableCell align="right">{item.price} EUR</TableCell>
                      <TableCell align="right">
                        {item.totalPrice}
                        EUR
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box sx={{width: '100%'}}>
                <Typography sx={{ml:2, pt:2, mb:1, fontSize: "15px", fontFamily: "Inter", fontWeight: 500}}>
                  Order Comment
                </Typography>
                <Typography sx={{ml:2, pt:2, mb:1, fontSize: "14px", fontFamily: "Inter", fontWeight: 400}}>{row.comment}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  bgcolor: "#f1f1f1",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Inter",
                    fontWeight: 600,
                    textTransform: "capitalize",
                    borderRadius: 0,
                  }}
                  onClick={() => handleExportPdf(row.id)}
                >
                  Export PDF
                </Button>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const orderStatuses = ["Processing", "Packed", "In your way", "Delivered"];

const OrdersList = () => {
  
  const [orders, setOrders] = React.useState<ReadOrder[] | []>([]);
  const [users, setUsers] = React.useState<UserRead[] | []>([]);
  const [is_super_admin, setIs_super_admin] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<UserRead | null>(null);
  const [zone, setZone] = React.useState("");
  const [selectedFromDate, setSelectedFromDate] = React.useState<Dayjs | null>(
    null
  );
  const [selectedToDate, setSelectedToDate] = React.useState<Dayjs | null>(
    null
  );
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 20;
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    (async () => {
      try{
        const response = await getAllUsers();
        if (response) {
          setUsers(response.users);
        }
      } catch (error){}
    })()
  },[]);

  React.useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = localStorage.getItem("user");
        if (user) {
          const userData = JSON.parse(user);
          if (userData) {
            const parsedUser = JSON.parse(user);
            setIs_super_admin(parsedUser.is_super_admin);
          }
        }
      } catch (error) {}
    };
  
    fetchUserData();
  }, []); // 

  const fetchOrders = React.useCallback(async () => {
    try {
      const options = {
        from_date: selectedFromDate
          ? selectedFromDate.format("YYYY-MM-DD")
          : "",
        to_date: selectedToDate ? selectedToDate.format("YYYY-MM-DD") : "",
        client_id: selectedUser?.id,
        zone: zone,
        page_number: page,
      };
      const response = await getOrders(options);
      if (response) {
        setOrders(response.orders);
        setPage(response.page_number);
        setCount(Math.ceil(response.total / rowsPerPage));
      }
    } catch (error) {
      setOrders([]);
      setPage(1);
      setCount(0);
    }
  }, [selectedFromDate, selectedToDate, selectedUser?.id, zone, page]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchOrders();
    }
    return () => {
      isMounted = false;
    };
  }, [fetchOrders]);

  const handleFromDateChange = (date: Dayjs | null) => {
    setSelectedFromDate(date);
    setPage(1); // Reset to first page on date change
  };

  const handleToDateChange = (date: Dayjs | null) => {
    setSelectedToDate(date);
    setPage(1); // Reset to first page on date change
  };

  const handleUserChange = async (event: React.ChangeEvent<{}>, user: UserRead | null) => {
    setSelectedUser(user);
  }

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPage(page);
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  };

  const handleReadOrderStatus = async (row: UpdateOrder) => {
    if (!row.visited) {
      try {
        const response = await updateOrderVisited(row);
        if (response) {
          // Update the visited status in the state
          setOrders((prevOrders) =>
            prevOrders.map((r) =>
              r.id === row.id ? { ...r, visited: true } : r
            )
          );
        }
      } catch (error) {
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <>
        <Box
          sx={{
            flexGrow: 1,
            m: 2,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  bgcolor: "#f1f1f1",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: 1,
                  borderTop: `2px solid ${theme.palette.primary.dark}`,
                }}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    sx={{ paddingLeft: "0px !important" }}
                  >
                    <Stack
                      spacing={2}
                      sx={{ paddingTop: "3px", paddingLeft: "15px" }}
                    >
                      <Breadcrumbs aria-label="breadcrumb">
                        <Typography
                          key="3"
                          sx={{
                            color: theme.palette.text.primary,
                            fontWeight: 600,
                            fontSize: "15px",
                            fontFamily: "Inter",
                          }}
                        >
                          Orders
                        </Typography>
                      </Breadcrumbs>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        pr: 1,
                      }}
                    >
                      {is_super_admin && (
                       <>
                         <Box>
                          <Autocomplete
                              options={zoneOptions}
                              getOptionLabel={(option) => option}
                              onChange={(event, newValue) => setZone(newValue || "Prishtine")}
                              sx={{
                                bgcolor: "#fff",
                                mr:1,
                                width: "250px",
                                "& .MuiOutlinedInput-root": {
                                  height: "30px",
                                  borderRadius: 0,
                                },
                                "& .MuiInputBase-input": {
                                  height: "30px",
                                  padding: "0 14px",
                                  fontSize: "13px",
                                  fontFamily: "Inter",
                                },
                                "& .MuiInputLabel-root": {
                                  color: "#5d5d5d",
                                  top: "-10px",
                                  fontSize: "13px",
                                  fontFamily: "Inter",
                                },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select Zone"
                                  variant="outlined"
                                  sx={{
                                    width: "100%",
                                    float: "left",
                                    bgcolor: "#fff",
                                    color: theme.palette.text.primary,
                                    border: 0,
                                  }}
                                  InputLabelProps={{
                                    ...params.InputLabelProps,
                                    sx: {
                                      color: theme.palette.text.primary,
                                      fontSize: "13px",
                                      fontFamily: "Inter",
                                      height: "100%",
                                      top: "-6px",
                                    },
                                  }}
                                  inputProps={{
                                    ...params.inputProps, // Ensure proper spreading of inputProps
                                    sx: {
                                      height: "10px",
                                      fontSize: "13px",
                                      fontFamily: "Inter",
                                    },
                                  }}
                                />
                              )}
                            />
                      </Box>
                        <Box>
                        <Autocomplete
                          disablePortal
                          id="users"
                          options={users}
                          renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option) => {
                            return (
                              <Box component="li" {...props} key={option.id}>
                                <Typography
                                  sx={{
                                    color: theme.palette.text.primary,
                                    fontWeight: 600,
                                    fontSize: "15px",
                                  }}
                                >
                                  {option.company}
                                </Typography>
                              </Box>
                            );
                          }}
                          getOptionLabel={(option) => {
                            return `${option.company}`;
                        }}
                          onChange={handleUserChange}
                          sx={{
                            bgcolor: "#fff",
                            mr:1,
                            width: "160px",
                            "& .MuiOutlinedInput-root": {
                              height: "30px",
                              borderRadius: 0,
                            },
                            "& .MuiInputBase-input": {
                              height: "30px",
                              padding: "0 14px",
                              fontSize: "13px",
                              fontFamily: "Inter",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#5d5d5d",
                              top: "-10px",
                              fontSize: "13px",
                              fontFamily: "Inter",
                            },
                          }}
                          renderInput={(params) => <TextField {...params} placeholder="Filter by client" />}
                        />
                      </Box>
                       </>
                      )}
                      
                      <Box>
                        <DatePicker
                          label="From Date"
                          value={selectedFromDate}
                          onChange={handleFromDateChange}
                          format="MM/DD/YYYY"
                          sx={{
                            bgcolor: "#fff",
                            mr: 1,
                            width: "160px",
                            "& .MuiOutlinedInput-root": {
                              height: "30px",
                              borderRadius: 0,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: 0,
                            },
                            "& .MuiInputBase-input": {
                              height: "30px",
                              padding: "0 14px",
                              fontSize: "13px",
                              fontFamily: "Inter",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#5d5d5d",
                              top: "-10px",
                              fontSize: "13px",
                              fontFamily: "Inter",
                            },
                          }}
                        />
                        <DatePicker
                          label="To Date"
                          value={selectedToDate}
                          onChange={handleToDateChange}
                          format="MM/DD/YYYY"
                          sx={{
                            bgcolor: "#fff",
                            mr: 1,
                            width: "160px",
                            "& .MuiOutlinedInput-root": {
                              height: "30px",
                              borderRadius: 0,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: 0,
                            },
                            "& .MuiInputBase-input": {
                              height: "30px",
                              padding: "0 14px",
                              fontSize: "13px",
                              fontFamily: "Inter",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#5d5d5d",
                              top: "-10px",
                              fontSize: "13px",
                              fontFamily: "Inter",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ overflowX: "auto" }}>
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "none",
                    minWidth: "800px",
                    border: "1px solid #f1f1f1",
                    p: 2,
                  }}
                >
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell
                          sx={{
                            fontFamily: "Inter",
                            fontWeight: 600,
                            pr: 0,
                            pb: 0,
                          }}
                        >
                          Order No
                        </TableCell>
                        <TableCell
                          sx={{ fontFamily: "Inter", fontWeight: 600, pb: 0 }}
                          align="center"
                        >
                          Zone
                        </TableCell>
                        <TableCell
                          sx={{ fontFamily: "Inter", fontWeight: 600, pb: 0 }}
                          align="center"
                        >
                          Date & Time
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "Inter",
                            fontWeight: 600,
                            pr: 0,
                            pb: 0,
                          }}
                          align="right"
                        >
                          Total value
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "Inter",
                            fontWeight: 600,
                            pr: 0,
                            pb: 0,
                          }}
                          align="right"
                        >
                          Payment method
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "Inter",
                            fontWeight: 600,
                            pr: 0,
                            pb: 0,
                          }}
                          align="right"
                        >
                          Status Order
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orders.map((row) => (
                        <Row key={row.id} row={row} onStatusChange={handleReadOrderStatus}/>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Stack spacing={2}>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", pt: 1 }}
                  >
                    <Pagination
                      count={count}
                      page={page}
                      variant="outlined"
                      shape="rounded"
                      onChange={handlePageChange}
                    />
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    </LocalizationProvider>
  );
};

// Options for the Autocomplete
const zoneOptions = [
  "Prishtine",
  "Fushe Kosove",
  "Prishtine (Kodra e Trimave)",
  "Peje",
  "Prizren",
  "Ferizaj",
  "Shqiperi",
  "Mitrovice",
  "Gjilan",
];

export default OrdersList;
